import React, { useState } from 'react';
import { ChakraProvider, Box, VStack, Heading, Input, Button, Text, List, ListItem } from '@chakra-ui/react';

function App() {
  const [tokens, setTokens] = useState(['', '']);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addToken = () => {
    if (tokens.length < 5) {
      setTokens([...tokens, '']);
    }
  };

  const removeToken = (index) => {
    if (tokens.length > 2) {
      const newTokens = tokens.filter((_, i) => i !== index);
      setTokens(newTokens);
    }
  };

  const handleTokenChange = (index, value) => {
    const newTokens = [...tokens];
    newTokens[index] = value;
    setTokens(newTokens);
  };

  const analyzeTokens = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch('http://localhost:5000/api/analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tokens: tokens.filter(t => t) }),
      });
      
      const data = await response.json();
      if (response.ok) {
        setResults(data);
      } else {
        setError(data.error);
      }
    } catch (err) {
      setError('Erreur de connexion au serveur');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChakraProvider>
      <Box p={8}>
        <VStack spacing={6} align="stretch">
          <Heading>Solana Token Tracker</Heading>
          
          {tokens.map((token, index) => (
            <Box key={index} display="flex" gap={2}>
              <Input
                value={token}
                onChange={(e) => handleTokenChange(index, e.target.value)}
                placeholder={`Adresse du token ${index + 1}`}
              />
              {tokens.length > 2 && (
                <Button onClick={() => removeToken(index)}>Supprimer</Button>
              )}
            </Box>
          ))}

          {tokens.length < 5 && (
            <Button onClick={addToken}>Ajouter un token</Button>
          )}

          <Button
            colorScheme="blue"
            onClick={analyzeTokens}
            isLoading={loading}
          >
            Analyser
          </Button>

          {error && (
            <Text color="red.500">{error}</Text>
          )}

          {results && (
            <Box>
              <Text>Nombre de wallets communs : {results.count}</Text>
              <List spacing={2}>
                {results.common_wallets.map((wallet, index) => (
                  <ListItem key={index}>
                    {wallet}
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;